export default {
  breakpoints: ['30em', '48em', '62em', '80em'],
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Roboto, Montserrat, sans-serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    neutral: {
      'black': '#111111',
      'superDarkGray': '#333333',
      'darkGray': '#555555',
      'gray': '#646161',
      'lightGray': '#C4C4C4', // base
      'superLightGray': '#F2F2F2', // hover
      'white': '#FFFFFF', // active
      '4F4F4F': '#4F4F4F', // active
      '888888': '#888888',
      'E1E1E1': '#E1E1E1',
    },
    primary: {
      '50': '#C7F9E3',
      '100': '#92F3D1',
      '200': '#58DCBA',
      '300': '#2EBAA2',
      '400': '#008C81', // base
      '500': '#005A64', // hover
      '600': '#004151', // active
      '700': '#003c4a',
      '800': '#003440',
      '900': '#002e39',
    },
    secondary: {
      '50': '#FFFCDD',
      '100': '#FDF9BC',
      '200': '#FAF59A',
      '300': '#F5ED7E',
      '400': '#F1E456', // base
      '500': '#CFC13E', // hover
      '600': '#ADA12B', // active
      '700': '#998f23',
      '800': '#776f19',
      '900': '#585210',
    },
    warning: {
      '50': '#FDEDCB',
      '100': '#FBD799',
      '200': '#F3B965',
      '300': '#E99A3E',
      '400': '#DA6D06', // base
      '500': '#BC5204', // hover
      '600': '#9D3D03', // active
      '700': '#8b3603',
      '800': '#792e01',
      '900': '#682801',
    },
    danger: {
      '50': '#FCDED3',
      '100': '#FAB6A9',
      '200': '#F0847A',
      '300': '#E45859',
      '400': '#D32737', // base
      '500': '#B51A39', // hover
      '600': '#971338', // active
      '700': '#8b0e31',
      '800': '#770827',
      '900': '#65031f',
    },
    success: {
      '50': '#DFFBD5',
      '100': '#B9F7AE',
      '200': '#86E780',
      '300': '#5DCE62',
      '400': '#2FAF40', // base
      '500': '#21963D', // hover
      '600': '#187D39', // active
      '700': '#156f32',
      '800': '#105c28',
      '900': '#0d5022',
    },
    info: {
      '50': '#CDEDFC',
      '100': '#9CD8FC',
      '200': '#6ABAF5',
      '300': '#439CEC',
      '400': '#0C72E0', // base
      '500': '#0958BF', // hover
      '600': '#0541A1', // active
      '700': '#033686',
      '800': '#022d70',
      '900': '#01245c',
    },
    black: {
      900: '#111111',
    },
    superDarkGray: {
      900: '#333333',
    },
    darkGray: {
      900: '#555555',
    },
    gray: {
      900: '#888888',
    },
    lightGray: {
      900: '#C4C4C4',
    },
    superLightGray: {
      900: '#F2F2F2',
    },
    red: {
      900: '#EA4335',
    },
    blue: {
      900: '#0075E1',
    },
    program: {
      50: 'var(--program-50)',
      100: 'var(--program-100)',
      400: 'var(--program-400)',
    },
  },
}
