import dayjs from 'dayjs'
import 'dayjs/locale/id'

export const formatDate = (value, format) => {
  let data = dayjs(value).locale('id').format(format)
  if (data.toLowerCase() === 'invalid date') data = value
  return data
}

const dateFormat = {
  default: 'DD MMMM YYYY', // 01 Januari 1991
  1: 'DD MMM YYYY', // 01 Jan 1991
  2: 'DD-MM YYYY', // 01 01 1991
  3: 'DD MMM YYYY HH:mm', // 01 Jan 1991 01:10
  4: 'HH:mm', // 01:10
  5: 'DD MMMM YYYY HH:mm', // 01 Januari 1991 01:10
  6: 'DD-MM-YYYY', // 01-01-1991
  7: 'DD MMMM YYYY, pukul HH:mm WIB', // 01 Januari 1991, pukul 01:10
  8: 'ddd, DD/MM/YYYY HH:mm', // 01 Januari 1991, pukul 01:10
}

export const formatDateV2 = (date, format = 'default') => {
  if (!date) return null
  const selectedFormat = dateFormat[format] ?? dateFormat.default
  const formattedDate = dayjs(date).locale('id').format(selectedFormat)
  if (formattedDate.toLowerCase() === 'invalid date') {
    return date
  }
  return formattedDate
}
